import { FunctionComponent } from 'react';
import { Backdrop, Box } from '@mui/material';

interface BackdropProps {
  open?: boolean;
}

const ManaBackdrop: FunctionComponent<BackdropProps> = ({ open = true }: BackdropProps) => (
  <Backdrop open={open} sx={{ display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
    <Box component='img' src='./images/icon-loader.gif' sx={{ height: '20vh' }} draggable={false}/>
  </Backdrop>
);

export default ManaBackdrop;
