import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authenticationReducer from './slices/authenticationSlice';

const store = configureStore({
  reducer: combineReducers({
    authentication: authenticationReducer
  })
});

type ManaState = ReturnType<typeof store.getState>;
type ManaDispatch = typeof store.dispatch;
type ManaThunk<ReturnType = void> = ThunkAction<ReturnType, ManaState, unknown, Action<string>>;

export type { ManaState, ManaDispatch, ManaThunk };
export { store };
