import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

// Set config defaults when creating the instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // The backend url
  timeout: parseInt(process.env.REACT_APP_BACKEND_TIMEOUT as string, 10) || 5000, // All requests using this instance will wait the time defined in milliseconds before timing out
  headers: { // The default headers
    Authorization: ''
  }
});

const updateToken = (token?: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
}

interface ManaResponse {
  code: number;
  message: string;
  data: any;
}

type ManaResponseSuccess = AxiosResponse<ManaResponse>;
type ManaResponseError = AxiosError<ManaResponse>;

export default axiosInstance;
export type { ManaResponse, ManaResponseSuccess, ManaResponseError };
export { updateToken };
