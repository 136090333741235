import {
  Context,
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import useManaSelector from '../hooks/useManaSelector';
import { ManaToken } from '../store/slices/authenticationSlice';
import useManaDispatch from '../hooks/useManaDispatch';
import { authenticateByToken } from '../store/middlewares/authenticationMiddleware';
import ManaBackdrop from '../components/ManaBackdrop';

interface ManaStorageContext {
  loaded: boolean;
}

const MSCtx = createContext<ManaStorageContext | null>(null);

const ManaStorageProvider: FunctionComponent<any> = ({ children }: PropsWithChildren<any>) => {

  const token: ManaToken = useManaSelector<ManaToken>((state) => state.authentication.token);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const dispatch = useManaDispatch();

  const handleOnLoadPage = () => {
    const storedToken: ManaToken = localStorage.getItem('token');
    if (storedToken) {
      localStorage.removeItem('token');
      dispatch(authenticateByToken(storedToken));
    }
    setLoaded(true);
  }

  const handleOnUnloadPage = () => token && localStorage.setItem('token', token);

  useLayoutEffect(handleOnLoadPage, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleOnUnloadPage);
    return () => window.removeEventListener('beforeunload', handleOnUnloadPage);
  }, [token]);

  return (
    <MSCtx.Provider value={{ loaded: isLoaded }}>
      {!isLoaded && <ManaBackdrop/> || children}
    </MSCtx.Provider>
  );
}

const useManaStorage = () => useContext<ManaStorageContext>(MSCtx as Context<ManaStorageContext>);

export default ManaStorageProvider;
export type { ManaStorageContext };
export { useManaStorage };
