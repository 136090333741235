import { FunctionComponent, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EnhancedEncryption, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import axios, { ManaResponseError, ManaResponseSuccess } from '../../../helper/axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCountdown } from 'react-countdown-typescript';
import ManaBackdrop from '../../ManaBackdrop';
import { useTranslation } from 'react-i18next';

interface IPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const PasswordForm: FunctionComponent = () => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'common']);

  const [isPasswordHidden, changePasswordVisibility] = useState<boolean>(true);
  const [isPasswordConfirmationHidden, changePasswordConfirmationVisibility] = useState<boolean>(true);
  const [isUpdating, setUpdatingStatus] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<number>(0);
  const [errorAlertStatus, setErrorAlertStatus] = useState<boolean>(false);
  const [timeLeft, { start }] = useCountdown(5 * 1000);

  const { register, formState: { errors }, getValues, handleSubmit } = useForm<IPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirmation: ''
    }
  });

  const handlePasswordVisibilityChange = () => changePasswordVisibility((oldVisibility) => !oldVisibility);
  const handlePasswordConfirmationVisibilityChange = () => changePasswordConfirmationVisibility((oldVisibility) => !oldVisibility);

  const handleOnSubmit: SubmitHandler<IPasswordForm> = (data) => {
    setUpdatingStatus(true);

    axios.patch(`/v1/auth/changePassword?token=${searchParams.get('token')!}`, { password: data.password }).then((response: ManaResponseSuccess) => {
      setUpdatingStatus(false);
      setErrorAlertStatus(false);
      setResponseCode(response.data.code);
      start();
    }).catch((error: ManaResponseError) => {
      setUpdatingStatus(false);
      setResponseCode(error.response!.data.code);
      setErrorAlertStatus(true);
    });
  };

  const handleErrorAlertOnClose = () => setErrorAlertStatus(false);
  const handleOnModalClose = () => navigate('/login', { state: searchParams.get('email') });

  useEffect(() => {
    if (timeLeft === 0) handleOnModalClose();
  }, [timeLeft]);

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(handleOnSubmit)}>
        <Collapse in={errorAlertStatus}>
          <Alert severity='error' onClose={handleErrorAlertOnClose} sx={{ mb: 2 }}>{responseCode}</Alert>
        </Collapse>
        <Stack spacing={2}>
          <TextField
            {...register('password', {
              required: t('forgotPassword.form.password.errors.new_password'),
            })}
            placeholder={t('forgotPassword.form.password.fields.new_password')}
            type={isPasswordHidden ? 'password' : 'text'}
            error={!!errors.password}
            helperText={errors?.password?.message ?? undefined}
            disabled={isUpdating}
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start' children={<Lock/>}/>,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handlePasswordVisibilityChange} edge='end' disabled={isUpdating}>
                    {isPasswordHidden ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            {...register('passwordConfirmation', {
              required: t('forgotPassword.form.password.errors.confirm_new_password'),
              validate: {
                matchesPreviousPassword: (value) => getValues('password') === value || t('forgotPassword.form.password.errors.password_not_match')
              }
            })}
            placeholder={t('forgotPassword.form.password.fields.confirm_new_password')}
            type={isPasswordConfirmationHidden ? 'password' : 'text'}
            error={!!errors.passwordConfirmation}
            helperText={errors?.passwordConfirmation?.message ?? undefined}
            disabled={isUpdating}
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start' children={<EnhancedEncryption/>}/>,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handlePasswordConfirmationVisibilityChange} edge='end' disabled={isUpdating}>
                    {isPasswordConfirmationHidden ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button type='submit' variant='contained' fullWidth
                  disabled={!!errors.password || !!errors.passwordConfirmation || isUpdating}>{t('forgotPassword.buttons.update_password')}</Button>
        </Stack>
      </Box>
      <Modal open={responseCode === 1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card sx={{ width: '30vw' }}>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box component='img' src='./images/colored/security.png' sx={{ width: '15vw' }}/>
            <Typography>{t('forgotPassword.modal.password.changed_success')}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={handleOnModalClose}>{t('actions.close', { ns: 'common' })} ({timeLeft / 1000})</Button>
          </CardActions>
        </Card>
      </Modal>
      <ManaBackdrop open={isUpdating}/>
    </>
  );

}

export default PasswordForm;
