import { FunctionComponent, PropsWithChildren } from 'react';
import useManaSelector from '../../hooks/useManaSelector';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Location } from 'history';

const ManaAuthentication: FunctionComponent<any> = ({ children }: PropsWithChildren<any>) => {

  const isAuthenticated: boolean = useManaSelector<boolean>((state) => !!state.authentication.token);
  const location: Location = useLocation();

  return !isAuthenticated && <Navigate to='/login' state={{ from: location }} replace/> || children || <Outlet/>;
}

export default ManaAuthentication;
