import { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { EMAIL_MAX_LENGTH, EMAIL_PATTERN } from '../../../utils/regexUtil';
import { Email } from '@mui/icons-material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCountdown } from 'react-countdown-typescript';
import axios, { ManaResponseError, ManaResponseSuccess } from '../../../helper/axios';
import ManaBackdrop from '../../ManaBackdrop';
import { useTranslation } from 'react-i18next';

interface IEmailForm {
  email: string;
}

const EmailForm: FunctionComponent = () => {

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'common']);

  const [isSending, setSendingStatus] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<number>(0);
  const [errorAlertStatus, setErrorAlertStatus] = useState<boolean>(false);
  const [timeLeft, { start }] = useCountdown(5 * 1000);

  const { register, formState: { errors }, getValues, setValue, handleSubmit } = useForm<IEmailForm>({
    defaultValues: {
      email: typeof location.state === 'string' && location.state as string || searchParams.has('email') && searchParams.get('email') || ''
    }
  });

  const handleOnSubmit: SubmitHandler<IEmailForm> = (data) => {
    setSendingStatus(true);

    return axios.post('/v1/auth/forgotPassword', { email: data.email }).then((response: ManaResponseSuccess) => {
      setValue('email', data.email);
      setSendingStatus(false);
      setErrorAlertStatus(false);
      setResponseCode(response.data.code);
      start();
    }).catch((error: ManaResponseError) => {
      setSendingStatus(false);
      setResponseCode(error.response!.data.code);
      setErrorAlertStatus(true);
    });
  };

  const handleErrorAlertOnClose = () => setErrorAlertStatus(false);
  const handleOnModalClose = () => navigate('/login', { state: getValues('email') });

  useEffect(() => {
    if (timeLeft === 0) handleOnModalClose();
  }, [timeLeft]);

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(handleOnSubmit)}>
        <Collapse in={errorAlertStatus}>
          <Alert severity='error' onClose={handleErrorAlertOnClose} sx={{ mb: 2 }}>{responseCode}</Alert>
        </Collapse>
        <Stack spacing={2}>
          <TextField
            {...register('email', {
              required: true,
              pattern: {
                value: EMAIL_PATTERN,
                message: t('errors.email_invalid', { ns: 'common' })
              },
              maxLength: {
                value: EMAIL_MAX_LENGTH,
                message: t('errors.email_max_length', { ns: 'common' })
              }
            })}
            placeholder={t('forgotPassword.form.email.email_recovery')}
            error={!!errors.email}
            helperText={errors?.email?.message ?? undefined}
            disabled={isSending}
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start' children={<Email/>}/>
            }}
          />
          <Button type='submit' variant='contained' fullWidth
                  disabled={!!errors.email || isSending}>{t('forgotPassword.buttons.recover_password')}</Button>
        </Stack>
      </Box>
      <Modal open={responseCode === 9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card sx={{ width: '30vw' }}>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box component='img' src='./images/colored/paper-plane.png' sx={{ width: '15vw' }}/>
            <Typography>{t('forgotPassword.modal.email.sent_success')}</Typography>
            <Typography>{getValues('email')}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={handleOnModalClose}>{t('actions.close', { ns: 'common' })} ({timeLeft / 1000})</Button>
          </CardActions>
        </Card>
      </Modal>
      <ManaBackdrop open={isSending}/>
    </>
  );

}

export default EmailForm;
