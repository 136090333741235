import { FunctionComponent, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ManaLocationState } from '../ManaRouter';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Email, Key, Visibility, VisibilityOff } from '@mui/icons-material';
import { SubmitHandler, useForm } from 'react-hook-form';
import useManaSelector from '../../hooks/useManaSelector';
import useManaDispatch from '../../hooks/useManaDispatch';
import { authenticateByCredentials } from '../../store/middlewares/authenticationMiddleware';
import { clearError, ManaToken } from '../../store/slices/authenticationSlice';
import ManaBackdrop from '../../components/ManaBackdrop';
import { EMAIL_MAX_LENGTH, EMAIL_PATTERN } from '../../utils/regexUtil';
import { useTranslation } from 'react-i18next';
import { generateResultMessage, MessagePayload, MessagingType } from "react-authentication-popup";

interface ILoginForm {
  email: string;
  password: string;
}

const Login: FunctionComponent = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'codes', 'common']);

  const userToken: ManaToken = useManaSelector<ManaToken>((state) => state.authentication.token);
  const errorCode: number | undefined = useManaSelector<number | undefined>((state) => state.authentication.error);
  const [isPasswordHidden, changePasswordVisibility] = useState<boolean>(true);
  const [isLogging, setLoggingStatus] = useState<boolean>(false);
  const [errorAlertStatus, setErrorAlertStatus] = useState<boolean>(false);
  const dispatch = useManaDispatch();

  const isAuthenticated: boolean = !!userToken;

  const { register, formState: { errors }, getValues, handleSubmit } = useForm<ILoginForm>({
    defaultValues: {
      email: typeof location.state === 'string' && location.state as string || '',
      password: ''
    }
  });

  const handleErrorAlertOnClose = () => setErrorAlertStatus(false);
  const handleErrorAlertOnEndTransition = () => dispatch(clearError());

  const handlePasswordVisibilityChange = () => changePasswordVisibility((oldVisibility) => !oldVisibility);
  const handleOnSubmit: SubmitHandler<ILoginForm> = (data) => {
    changePasswordVisibility(true);
    setLoggingStatus(true);
    handleErrorAlertOnClose();
    dispatch(authenticateByCredentials(data.email, data.password));
  };

  const handleOnForgotPassword = () => {
    navigate('/forgotPassword', { state: getValues('email') });
  };

  const postAuthenticationMessage = () => {
    if (!isAuthenticated) return;

    const targetWindow: Window = window.opener;
    return targetWindow.postMessage(generateResultMessage<string>(userToken!), '*');
  };

  useEffect(() => {
    setLoggingStatus(isAuthenticated);
    if (isAuthenticated) {
      const isAuthenticationPopup: boolean = Object.fromEntries(new URLSearchParams(window.location.search).entries())['response_type'] === 'token';

      if (isAuthenticationPopup)
        return postAuthenticationMessage();

      const from: string = (location.state as ManaLocationState)?.from?.pathname || '/';
      return navigate(from, { replace: true });
    }
  }, [isAuthenticated]);

  const handlePostMessage = (event: MessagePayload<string>) => {
    if (event.data.message === MessagingType.REQUEST_MESSAGE)
      return postAuthenticationMessage();
  }

  useEffect(() => {
    if (errorCode) {
      setLoggingStatus(false);
      setErrorAlertStatus(true);
    }

    window.addEventListener('message', handlePostMessage);
    return () => window.removeEventListener('message', handlePostMessage);
  }, [errorCode]);

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: 'url(./images/home-bg.svg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>
      <Box>
        <Box>
          <Box component='img' src='./images/icon-128x128.png' width={64}/>
          <Typography variant='h4' sx={{ fontWeight: 'bold' }}>{t('login.header.title')}</Typography>
          <Typography variant='subtitle1'
                      sx={{ textTransform: 'uppercase', fontSize: '.75rem' }}>{t('login.header.slogan')}</Typography>
        </Box>
        <Divider variant='middle' sx={{ my: 1 }}/>
        <Card sx={{ width: '30rem' }}>
          <CardContent>
            <Collapse in={errorAlertStatus} onExited={handleErrorAlertOnEndTransition}>
              <Alert severity='error' onClose={handleErrorAlertOnClose}
                     sx={{ mb: 2 }}>{errorCode ? t(`${errorCode}`, { ns: 'codes' }) : ''}</Alert>
            </Collapse>
            <Box component='form' onSubmit={handleSubmit(handleOnSubmit)}>
              <Stack spacing={2}>
                <TextField
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: t('errors.email_invalid', { ns: 'common' })
                    },
                    maxLength: {
                      value: EMAIL_MAX_LENGTH,
                      message: t('errors.email_max_length', { ns: 'common' })
                    }
                  })}
                  placeholder={t('login.modal.email')}
                  error={!!errors.email}
                  helperText={errors?.email?.message ?? undefined}
                  disabled={isLogging}
                  variant='outlined'
                  InputProps={{
                    startAdornment: <InputAdornment position='start' children={<Email/>}/>
                  }}
                />
                <TextField
                  {...register('password', {
                    required: true,
                  })}
                  placeholder={t('login.modal.password')}
                  type={isPasswordHidden ? 'password' : 'text'}
                  error={!!errors.password}
                  helperText={errors?.password?.message ?? undefined}
                  disabled={isLogging}
                  variant='outlined'
                  InputProps={{
                    startAdornment: <InputAdornment position='start' children={<Key/>}/>,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handlePasswordVisibilityChange} edge='end' disabled={isLogging}>
                          {isPasswordHidden ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button type='submit' variant='contained' fullWidth
                        disabled={!!errors.email || !!errors.password || isLogging}>{t('login.buttons.login')}</Button>
              </Stack>
            </Box>
          </CardContent>
          <Divider variant='middle'/>
          <CardActions sx={{ flexDirection: 'column', alignItems: 'start', mx: 1 }} disableSpacing>
            <Link onClick={handleOnForgotPassword} underline='hover'
                  sx={{ cursor: 'pointer' }}>{t('login.modal.forgot_password')}</Link>
            <Typography>{t('login.modal.sign_up_text')} <Link component={RouterLink} to='/signUp'
                                                              underline='hover'>{t('login.modal.sign_up')}</Link></Typography>
          </CardActions>
        </Card>
      </Box>
      <Divider sx={{ my: 1 }}/>
      <Typography sx={{ color: 'gray' }}>
        <Link href='' underline='hover'>{t('login.footer.contact')}</Link> · <Link href=''
                                                                                   underline='hover'>{t('login.footer.privacy')}</Link> · <Link
        href=''
        underline='hover'>{t('login.footer.tos')}</Link>
      </Typography>
      <ManaBackdrop open={isLogging}/>
    </Box>
  );

};

export default Login;
