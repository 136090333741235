import { Box, Button, Typography } from '@mui/material';
import useManaSelector from '../../hooks/useManaSelector';
import { logOut, ManaToken } from '../../store/slices/authenticationSlice';
import useManaDispatch from '../../hooks/useManaDispatch';
import { Link } from 'react-router-dom';

const Dashboard = () => {

  const token: ManaToken = useManaSelector<ManaToken>((state) => state.authentication.token);
  const dispatch = useManaDispatch();

  const handleLogOut = () => dispatch(logOut());

  return (
    <Box>
      <Typography>Your token: {token}</Typography>
      <Button component={Link} to='settings' variant='contained'>Go to settings</Button>
      <Button variant='contained' onClick={handleLogOut}>Log out</Button>
    </Box>
  );
}

export default Dashboard;
