import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Settings = () => (
  <Box>
    <Typography>Settings</Typography>
    <Button component={Link} to='/' variant='contained'>Go home</Button>
  </Box>
);

export default Settings;
