import common from './common.json';

// API Codes
import codes from './api_codes';

// Authentication
import authenticationLogin from './authentication/login.json';
import authenticationForgotPassword from './authentication/forgot_password.json';

const en = {
  common,
  codes,
  authentication: {
    login: authenticationLogin,
    forgotPassword: authenticationForgotPassword
  }
};

export default en;
