import { ManaThunk } from '../store';
import axios, { ManaResponse, ManaResponseError, ManaResponseSuccess, updateToken } from '../../helper/axios';
import { clearError, logInFail, logInSuccess } from '../slices/authenticationSlice';

const authenticateByCredentials = (email: string, password: string): ManaThunk => (dispatch) => {
  dispatch(clearError());
  axios.post('/v1/auth/login', { email, password }).then((response: ManaResponseSuccess) => {
    const responseData: ManaResponse = response.data;
    if (responseData.code === 15) {
      const token: string = responseData.data['token'];
      dispatch(authenticateByToken(token));
    } else dispatch(logInFail(responseData.code));
  }).catch((error: ManaResponseError) => dispatch(logInFail(error.response!.data.code)));
}

const authenticateByToken = (token: string): ManaThunk => (dispatch) => {
  updateToken(token);
  dispatch(logInSuccess(token));
}

export { authenticateByCredentials, authenticateByToken };
