import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languages, { LanguageData } from '../utils/localeUtil';

import en from './en';
import pt from './pt';

i18n
.use(initReactI18next)
.init({
  debug: process.env.NODE_ENV === 'development',
  lng: languages[0].language,
  fallbackLng: languages[0].language,
  ns: ['common'],
  defaultNS: 'common',
  preload: languages.map<string>(({ language }: LanguageData) => language),
  resources: { en, pt }
});
