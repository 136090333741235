interface LanguageData {
  language: string;
  icon: string;
}

const languages: LanguageData[] = [
  { language: 'en', icon: 'US' },
  { language: 'pt', icon: 'PT' }
];

export default languages;
export type { LanguageData };
