import { AuthenticationState } from '../slices/authenticationSlice';
import { PayloadAction } from '@reduxjs/toolkit';

const logInSuccess = (state: AuthenticationState, action: PayloadAction<string>): AuthenticationState => ({
  ...state,
  token: action.payload,
  error: undefined
});

const logInFail = (state: AuthenticationState, action: PayloadAction<number>): AuthenticationState => ({
  ...state,
  token: undefined,
  error: action.payload
});

const logOut = (state: AuthenticationState): AuthenticationState => ({
  ...state,
  token: undefined,
  error: undefined
});

const clearError = (state: AuthenticationState): AuthenticationState => ({
  ...state,
  error: undefined
});

export {
  logInSuccess,
  logInFail,
  logOut,
  clearError
};
