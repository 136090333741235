import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ManaAuthentication from '../components/authentication/ManaAuthentication';
import Dashboard from './dashboard/Dashboard';
import Settings from './settings/Settings';
import Login from './authentication/Login';
import ForgotPassword from './authentication/ForgotPassword';

type ManaLocationState = {
  from: {
    pathname: string;
  } | undefined;
} | undefined;

const ManaRouter: FunctionComponent = () => (
  <Routes>
    <Route path='/' element={<ManaAuthentication/>}>
      <Route index element={<Dashboard/>}/>
      <Route path='settings' element={<Settings/>}/>
    </Route>
    <Route path='/login' element={<Login/>}/>
    <Route path='/forgotPassword' element={<ForgotPassword/>}/>
  </Routes>
);

export default ManaRouter;
export type { ManaLocationState };
