import { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Box, Card, CardContent, Collapse, Divider, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EmailForm from '../../components/authentication/forgot_password/EmailForm';
import PasswordForm from '../../components/authentication/forgot_password/PasswordForm';
import ManaBackdrop from '../../components/ManaBackdrop';
import axios, { ManaResponseError, ManaResponseSuccess } from '../../helper/axios';
import { useTranslation } from 'react-i18next';

const ForgotPassword: FunctionComponent = () => {

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'codes']);

  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [isTokenStillValid, setTokenValidationStatus] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);
  const [errorAlertStatus, setErrorAlertStatus] = useState<boolean>(false);

  const handleErrorAlertOnEndTransition = () => setErrorCode(undefined);

  const handleGoBack = () => navigate(-1);

  useEffect(() => {
    if (searchParams.has('token')) {
      axios.post(`/v1/auth/forgotPassword?token=${searchParams.get('token')}`).then((response: ManaResponseSuccess) => {
        setTokenValidationStatus(true);
        setErrorAlertStatus(false);
      }).catch((error: ManaResponseError) => {
        setTokenValidationStatus(false);
        setErrorCode(error.response!.data.code);
        setErrorAlertStatus(true);
      }).finally(() => setLoadingStatus(false));
    } else {
      setLoadingStatus(false);
    }
  }, []);

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: 'url(./images/home-bg.svg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>
      <Box>
        <Box>
          <Box component='img' src='./images/icon-128x128.png' width={64}/>
          <Typography variant='h4' sx={{ fontWeight: 'bold' }}>{t('forgotPassword.header.title')}</Typography>
          <Typography variant='subtitle1' sx={{
            textTransform: 'uppercase',
            fontSize: '.75rem'
          }}>{t('forgotPassword.header.slogan')}</Typography>
        </Box>
        <Divider variant='middle' sx={{ my: 1 }}/>
        <Card sx={{ width: '30vw' }}>
          <CardContent>
            <Collapse in={errorAlertStatus} onExited={handleErrorAlertOnEndTransition}>
              <Alert severity='error'
                     sx={{ mb: 2 }}>{errorCode ? t(`${errorCode}`, { ns: 'codes' }) : `${errorCode}`}</Alert>
            </Collapse>
            {isTokenStillValid && searchParams.has('token') && <PasswordForm/> || <EmailForm/>}
          </CardContent>
        </Card>
      </Box>
      <Divider sx={{ my: 1 }}/>
      {location.key && location.key !== 'default' &&
          <Link onClick={handleGoBack} underline='hover'
                sx={{ cursor: 'pointer' }}>{t('forgotPassword.footer.remember_message')}</Link> ||
          <Link component={RouterLink} to='/login'
                underline='hover'>{t('forgotPassword.footer.mistaken_message')}</Link>
      }
      <ManaBackdrop open={isLoading}/>
    </Box>
  );
}

export default ForgotPassword;
