import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import ManaStorageProvider from '../providers/ManaStorageProvider';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import ManaRouter from './ManaRouter';

const ManaApplication: FunctionComponent = () => (
  <Provider store={store}>
    <ManaStorageProvider>
      <BrowserRouter>
        <CssBaseline>
          <ManaRouter/>
        </CssBaseline>
      </BrowserRouter>
    </ManaStorageProvider>
  </Provider>
);

export default ManaApplication;
