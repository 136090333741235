import { createSlice } from '@reduxjs/toolkit';
import * as reducers from '../reducers/authenticationReducer';

type ManaToken = string | null | undefined;

interface AuthenticationState {
  token: ManaToken;
  error?: number;
}

const initialState: AuthenticationState = {
  token: undefined,
  error: undefined
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers
});

export default authenticationSlice.reducer;
export type { ManaToken, AuthenticationState };
export const { logInSuccess, logInFail, logOut, clearError } = authenticationSlice.actions;
